import { Footer, ButtonSubmit, ButtonBack } from '@components'
import { campaignService } from '@services'
import { Campaign } from '@utils'

import {
  HTTP_CODE_OK,
  CAMPAIGN_IS_EXPIRED,
  SERIAL_CODE_DEFAULT,
  HTTP_CODE_IS_EXPIRED,
  HTTP_CODE_RATE_LIMIT_EXCEED,
  CAMPAIGN_SMS_TYPE,
  CAMPAIGN_SERIAL_TYPE,
  HTTP_CODE_BAD_REQUEST,
  HTTP_CODE_PHONE_NUMBER_ERRORS,
  HTTP_PHONE_NUMBER_NOT_IN_WHITE_LIST,
  APP_LANG_VN
} from '@constants'
import { LANGUAGES } from '@i18n'

export default {
  name: 'SmsSerialAuth',
  components: { Footer, ButtonSubmit, ButtonBack },
  props: {
    campaignUrlName: String,
    campaignUrl: String,
    campaignInfos: Object,
    campaignSettings: Object,
    campaignCountry: Object
  },
  data() {
    return {
      input: {
        serialNumber: '',
        smsPhone: ''
      },
      canPhoneSubmit: false,
      canSerialSubmit: false,
      serialNumberInvalid: false,
      phoneNumberInvalid: false,
      phoneNumberNotInWhiteList: false,
      phoneHasSent: false,
      messageErrors: '',
      smsMessageErrors: '',
      isPhoneCallingProcessing: false,
      isSerialCallingProcessing: false,
      utmCampaign: '',
      utmMedium: '',
      utmSource: '',
      maskSerialNumber: 'AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AA',
      maskPhoneNumber: '00-0000-0000-00000'
    }
  },
  methods: {
    async requestSendSMS(e) {
      e.preventDefault()
      // Validate input
      if (this.validateSmsBeforeSubmit()) {
        this.isPhoneCallingProcessing = true
        this.phoneHasSent = false
        // Verify phone via call API
        await campaignService
          .sendSmsSerial({
            campaign_url_name: this.campaignUrlName,
            campaign_url: this.campaignUrl,
            phone_number: this.input.smsPhone.replace(/-/g, ''),
            utm_campaign: this.utmCampaign,
            utm_medium: this.utmMedium,
            utm_source: this.utmSource
          })
          .then((res) => {
            if (res.status === HTTP_CODE_OK) {
              this.phoneHasSent = true
            } else if (
              res.status === HTTP_CODE_BAD_REQUEST &&
              HTTP_CODE_PHONE_NUMBER_ERRORS.includes(res.data.status)
            ) {
              this.$refs.phoneNumber.focus()
              let lang = Campaign.getLanguage();

              // handle fo Message of lang vn just font-size 10px
              if (res.data.status == HTTP_PHONE_NUMBER_NOT_IN_WHITE_LIST && lang.language_id == APP_LANG_VN) {
                this.phoneNumberNotInWhiteList = true
              } else {
                this.phoneNumberInvalid = true
              }
              this.smsMessageErrors = this.$t('sms_serial_v2_msg_error_code_' + res.data.status)
              this.canPhoneSubmit = false
            } else {
              this.$router.push({
                name: 'campaign.error',
                replace: true
              })
            }
          })
        this.isPhoneCallingProcessing = false
      }
    },
    async joinCampaign(e) {
      e.preventDefault()
      // Validate input
      if (this.validateSerialBeforeSubmit()) {
        this.isSerialCallingProcessing = true
        await campaignService
          .verifySmsSerial({
            campaign_url_name: this.campaignUrlName,
            campaign_url: this.campaignUrl,
            sms_serial_code: this.input.serialNumber.replace(/-/g, '').toUpperCase()
          })
          .then((res) => {
            const data = res.data
            if (data?.lottery_url !== undefined && res.status === HTTP_CODE_OK) {
              const lotteryUuid = data.lottery_url.substring(
                data.lottery_url.lastIndexOf('/') + 1
              )
              this.$router.push({
                name: 'lottery.game',
                params: { lotteryUuid },
                replace: true
              }).catch(() => {})
            } else {
              // Handle for campaign is expired
              if (data.status === HTTP_CODE_IS_EXPIRED) {
                this.$router.go({
                  name: 'campaign.home',
                  params: { campaignUrlName: data.data.campaign_url_name, campaignUrl: data.data.campaign_url }
                })
              } else if (data.status === HTTP_CODE_RATE_LIMIT_EXCEED) {
                this.serialNumberInvalid = true,
                this.messageErrors = this.$t('verify_serial_exceeds_limit'),
                this.canSerialSubmit = false
              } else {
                this.serialNumberInvalid = true,
                this.messageErrors = this.$t('serial_invalid_code_msg'),
                this.canSerialSubmit = false
              }
            }
          })
        this.isSerialCallingProcessing = false
      }
    },
    validateSmsBeforeSubmit() {
      if (this.canPhoneSubmit) {
        // Check valid of phone number, phone number must from 10 to 15 character
        var phoneNumber = this.input.smsPhone.replace(/-/g, '')
        if (phoneNumber.match(/^[0-9]{10,15}$/)) {
          return true
        } else {
          this.$refs.phoneNumber.focus()
          this.phoneNumberInvalid = true
          this.smsMessageErrors = this.$t('sms_invalid_phone')
          this.canPhoneSubmit = false

          return false
        }
      } else {
        this.$refs.phoneNumber.focus()

        return false
      }
    },
    validateSerialBeforeSubmit() {
      if (this.canSerialSubmit) {
        if (this.input.serialNumber !== '') {
          return true
        } else {
          this.$refs.serialNumber.focus()
          this.serialNumberInvalid = true
          this.messageErrors = this.$t('serial_wrong_format')
          this.canSerialSubmit = false

          return false
        }
      } else {
        this.$refs.serialNumber.focus()

        return false
      }
    }
  },
  watch: {
    'input.smsPhone'(value) {
      // Check End-user enter phone number is it valid or not to show submit button
      if (value) {
        this.phoneNumberInvalid = false
        this.phoneNumberNotInWhiteList = false
        this.phoneHasSent = false

        return (this.canPhoneSubmit = value.length > 0)
      }
    },
    'input.serialNumber'(value) {
      // Check End-user enter Serial code is it valid or not to show submit button
      if (value) {
        this.serialNumberInvalid = false

        return (this.canSerialSubmit = value.length > 0)
      }
    }
  },
  computed: {
    createSetAppContainerStyles() {
      if (this.campaignSettings) {
        const objStyles = {
          '--app-container-bg-color': this.campaignSettings.preset_bg_color,
          '--app-container-text-color':
                this.campaignSettings.preset_text_color,
          '--app-container-font-family': Campaign.getLanguage().font_type
        }
        this.$setAppStyles(objStyles)
      }
    }
  },
  created() {
    // Check campaign is expired
    const storageCampaignInfos = Campaign.getCampaignInfos()

    if (storageCampaignInfos.is_expired === CAMPAIGN_IS_EXPIRED) {
      this.$router.push({
        name: 'campaign.home',
        params: {
          campaignUrlName: this.campaignUrlName,
          campaignUrl: this.campaignUrl
        },
        replace: true
      })
    }

    if (storageCampaignInfos.campaign_type === CAMPAIGN_SMS_TYPE) {
      this.$router.push({
        name: 'campaign.auth.sms',
        replace: true
      })
    }

    if (this.$route.query.code || storageCampaignInfos.campaign_type === CAMPAIGN_SERIAL_TYPE) {
      this.$router.push({
        name: 'campaign.auth.serial',
        query: {
          code: this.$route.query.code ? this.$route.query.code : SERIAL_CODE_DEFAULT
        },
        replace: true
      })
    }

    // Get utm value
    this.utmCampaign = this.$route.query.utm_campaign
    this.utmMedium = this.$route.query.utm_medium
    this.utmSource = this.$route.query.utm_source
    // Set language
    this.$i18n.locale = LANGUAGES[this.campaignCountry.language]
  }
}
